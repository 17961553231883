import {Component, OnInit, ViewChild} from '@angular/core';
import { CalendarComponent } from 'ng-fullcalendar';
import { Options } from 'fullcalendar';
import {AuthService} from "../auth.service";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material";
import {NgForm} from "@angular/forms";



@Component({
  selector: 'app-kalendar',
  templateUrl: './kalendar.component.html',
  styleUrls: ['./kalendar.component.css']
})



export class KalendarComponent implements OnInit {

    taskup;idje;idupravujem;color: any;
   drawero;niejet;aktoniejeur;datumod:any;
    drawer:any;
    displayEvent: any;
    calendarOptions: Options;
    @ViewChild(CalendarComponent) ucCalendar: CalendarComponent;
  constructor(
      private  Auth: AuthService,
      public snackBar: MatSnackBar
      ) { }

  ngOnInit() {

      var iduser = sessionStorage.getItem('id');

      this.Auth.getToKalendar(iduser).subscribe(data => {
         console.log(data);

          this.calendarOptions = {
          editable: true,
          eventLimit: false,
          slotLabelFormat:"HH:mm",
          locale: 'sk',
          header: {
              left: 'prev,next today',
              center: 'title',
              right: 'month,agendaWeek,agendaDay,listMonth'
          },
              navLinks: true,
              navLinkDayClick: function() {
                  console.log('day');
              },

          events: data[0],
      };

  })
  }
    dayClick(model: any) {
        model = {
            dates: model.date
        };
        var dad = model['dates'];

  this.ucCalendar.fullCalendar('changeView', 'agendaDay')
  this.ucCalendar.fullCalendar('gotoDate', dad);

    }

    clickButton(model: any) {
        this.displayEvent = model;
        console.log(model)

    }
    eventClick(model: any) {
        this.niejet = true;
        this.aktoniejeur = true;
        model = {
            event: {
                id: model.event.id,
            color: model.event.color,
                task: model.event.task,
                urobene: model.event.urobene,
                title: model.event.title,
            },
            duration: {}
        };
        this.taskup = model['event']['title'];
        this.idupravujem = model['event']['id'];
        this.color = model['event']['color'];
      console.log(model['event']['urobene']);
      if(model['event']['task'] != 0){
            this.niejet = false;
      }
      if(model['event']['urobene'] != 0){
          this.aktoniejeur = false;
      }
          }

    prikl(model: any) {
        model = {
            dates: model.detail.date.format()
        };
        //console.log(model['dates']);
        this.datumod = model['dates'];
    }

    updateEvent(model: any) {
        model = {
                id: model.event.id,
                start: model.event.start,
                end: model.event.end,
        };

        this.Auth.kalendarpresun(model).subscribe(data => {
         if(data == 'task'){
             let config = new MatSnackBarConfig();
             config.duration = 35000;
             config.panelClass = ['red_class'];
             this.snackBar.open('Tento task sa nedá premiestniť!', "Ok", config);
         }else{
             let config = new MatSnackBarConfig();
             config.duration = 5000;
             config.panelClass = ['green_class'];
             this.snackBar.open('Task presunutý!', "Ok", config);
         }
        })
    }

    poslitask(form:NgForm){
        var iduser = sessionStorage.getItem('id');
        let dataFa = {
            'iduser': iduser,
            'datumod': form.value.datumod,
            'casod':form.value.casod,
            'datumdo': form.value.datumdo,
            'casdo':form.value.casdo,
            'task':form.value.task
        };
        this.Auth.pridajtask(dataFa).subscribe(data => {
            if(data == 'ano'){
                console.log(data);
                window.location.href = '/kalendar';
            }else{
                console.log(data);

            }
        });
    }

    akttask(form:NgForm){
        var iduser = sessionStorage.getItem('id');
        let dataFa = {
            'iduser': iduser,
            'idupravujem': form.value.idupravujem,
            'taskup':form.value.taskup
        };
        console.log(dataFa);
        this.Auth.upravtask(dataFa).subscribe(data => {
            if(data == 'ano'){
                console.log(data);
                window.location.href = '/kalendar';
            }else{
                let config = new MatSnackBarConfig();
                config.duration = 5000;
                config.panelClass = ['red_class'];
                this.snackBar.open(''+data+'', "Ok", config);

            }
        });
    }

    vymtask(id){
        this.Auth.vymatask(id).subscribe(data => {
            if(data == 'ano'){
                console.log(data);
                window.location.href = '/kalendar';
            }else{
                let config = new MatSnackBarConfig();
                config.duration = 5000;
                config.panelClass = ['red_class'];
                this.snackBar.open(''+data+'', "Ok", config);

            }
        });

    }
    taskok(id){
        this.Auth.taskok(id).subscribe(data => {
            if(data == 'ano'){
                console.log(data);
                window.location.href = '/kalendar';
            }else{
                let config = new MatSnackBarConfig();
                config.duration = 5000;
                config.panelClass = ['red_class'];
                this.snackBar.open(''+data+'', "Ok", config);

            }
        });

    }




}
