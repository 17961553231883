import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../auth.service";




@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    meno:any;
    isAdmin:boolean;

    constructor(private Auth:AuthService,
                private router:Router){  }

  ngOnInit() {
      this.meno =  sessionStorage.getItem('user');


  }

    odhlasma(){
        sessionStorage.clear();
        console.log(sessionStorage.getItem('user'));
        window.location.href = '/';
    }





}