import { Component, OnInit } from '@angular/core';
import { MatTableDataSource} from "@angular/material";
import {AuthService} from "../auth.service";

@Component({
  selector: 'app-projekty',
  templateUrl: './projekty.component.html',
  styleUrls: ['./projekty.component.css']
})
export class ProjektyComponent implements OnInit {
    ukoncene;tasky;percent;task;deadline;idko;datumal;popisala:any;
    arr:any;
    dataSource:any;
    displayedColumns: string[] = ['firma', 'farba', 'deadline','tasky','urobene','pozri' ];
    constructor(
        private  Auth: AuthService,
    ) {}

  ngOnInit() {
      this.arr = [{  popis: '',pocet: '',jednotkova: '',celkom:''}];
      var iduser = sessionStorage.getItem('id');
    this.Auth.loadProjekt(iduser).subscribe(data => {
          console.log(data);
          this.dataSource = new MatTableDataSource<PeriodicElement>(data[0]);
      });
  }



    pozriPro(id){
        this.Auth.loOnePro(id).subscribe(data => {
            this.idko = data[0].id;
          this.datumal = data[0].datum;
            this.popisala = data[0].popis;
            this.ukoncene = data[0].ukoncene;
        });
        this.Auth.loadOneTask(id).subscribe(data => {
            console.log(data[0]);
            this.tasky = data[0];

        });
    }


    ukoncenetask(id,idko){
        let data = {
            'id':id,
            'idko': idko,
        };
        this.Auth.ukonctask(data).subscribe(data => {
            console.log(data[0]);
            this.tasky = data[0];
        });
    }
    neukoncenetask(id,idko){
        let data = {
            'id':id,
            'idko': idko,
        };
        this.Auth.neukonctask(data).subscribe(data => {
            console.log(data[0]);
            this.tasky = data[0];
        });
    }

}

export interface PeriodicElement {
    firma: string;
    farba: string;
    deadline: string;
    tasky: number;
    urobene: number;
    pozri: number;
}

