import {Component, OnInit} from '@angular/core';
import { MatTableDataSource} from "@angular/material";
import {AuthService} from "../auth.service";

@Component({
    selector: 'app-firmy',
    templateUrl: './firmy.component.html',
    styleUrls: ['./firmy.component.css']
})
export class FirmyComponent implements OnInit {
    idkold;nazovuold;adresauold;mestouold;pscuold;icouold;dicuold;icdicuold;emailuold;telefonuold;webuold;textuold;konateluold;kontaktnaosobauold;emailkontaktnaosobauold:any;
    iduser;dataSource:any;
    constructor(
        private  Auth: AuthService,
    ) {}

    displayedColumns: string[] = ['firma', 'email', 'telefon','web', 'pozri' ];

    ngOnInit() {
        var iduser = sessionStorage.getItem('id');
        this.Auth.loadFirm(iduser).subscribe(data => {
            console.log(data);
            this.dataSource = new MatTableDataSource<PeriodicElement>(data[0]);

        })

    }


    pozriFi(id){
        this.Auth.loOneFir(id).subscribe(data => {
            this.idkold =  data[0].id;
            this.nazovuold = data[0].nazov;
            this.emailuold = data[0].email;
            this.telefonuold = data[0].tel;
            this.webuold = data[0].web;
            this.textuold = data[0].text;
        });
    }


}

export interface PeriodicElement {
    firma: string;
    email: string;
    telefon: string;
    web: string;
    pozri: number;
}

