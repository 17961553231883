
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material";
import {RouterModule, Router} from "@angular/router";



@Injectable({
    providedIn: 'root'
})
export class AuthService {



    constructor(
        public http: HttpClient,
        public snackBar: MatSnackBar,
        private router:Router,
        private Auth: AuthService
    ) {
    }




    getUserDetails(username,password){
        let data = {
            'username': username,
            'password': password
        };
        let headers = {
            // 'Content-Type': 'application/json'
        };
        return this.http.post('https://crm.becrea.sk/inc/login.php',  data);

    }


    loadFirm(id){
        return this.http.post('https://crm.becrea.sk/inc/loadFirm.php',id);
    }
    loadProjekt(id){
        return this.http.post('https://crm.becrea.sk/inc/loadProjekt.php',id);
    }

      loOneFir(ida){
        return this.http.post('https://crm.becrea.sk/inc/loOneFir.php',ida);
    }

    loOnePro(ida){
        return this.http.post('https://crm.becrea.sk/inc/loOnePro.php',ida);
    }

    loadOneTask(id){
        return this.http.post('https://crm.becrea.sk/inc/loadOneTask.php',id);
    }

    ukonctask(data){
        return this.http.post('https://crm.becrea.sk/inc/ukonctask.php',data);
    }
    neukonctask(data){
        return this.http.post('https://crm.becrea.sk/inc/neukonctask.php',data);
    }
    loadKlTime(id){
        return this.http.post('https://crm.becrea.sk/inc/loadKlTime.php',id);
    }
    getToTime(data){
        return this.http.post('https://crm.becrea.sk/inc/getToTime.php',data);
    }
    loadTimes(id){
        return this.http.post('https://crm.becrea.sk/inc/loadTimes.php',id);
    }
    getNewTime(data){
        return this.http.post('https://crm.becrea.sk/inc/getNewTime.php',data);
    }
    getToKalendar(data){
        return this.http.post('https://crm.becrea.sk/inc/loadtokalendar.php',data);
    }
    kalendarpresun(data){
        return this.http.post('https://crm.becrea.sk/inc/kalendarpresun.php',data);
    }
    pridajtask(data){
        return this.http.post('https://crm.becrea.sk/inc/pridajtask.php',data);
    }
    upravtask(data){
        return this.http.post('https://crm.becrea.sk/inc/upravtask.php',data);
    }
    vymatask(data){
        return this.http.post('https://crm.becrea.sk/inc/vymatask.php',data);
    }
    taskok(data){
        return this.http.post('https://crm.becrea.sk/inc/taskok.php',data);
    }
    loadDas(iduser){
        return this.http.post('https://crm.becrea.sk/inc/loadDas.php',iduser);
    }
    loadPrac(iduser){
        return this.http.post('https://crm.becrea.sk/inc/loadPrac.php',iduser);
    }
    poznamkaAdd(data){
        return this.http.post('https://crm.becrea.sk/inc/poznamkaAdd.php',data);
    }
    taskRob(data){
      return this.http.post('https://crm.becrea.sk/inc/taskRob.php',data);
    }
  odoberPra(data){
      return this.http.post('https://crm.becrea.sk/inc/odoberPra.php',data);
    }
  odoberUrob(data){
      return this.http.post('https://crm.becrea.sk/inc/odoberUrob.php',data);
    }
    loadTasky(){
      return this.http.post('https://crm.becrea.sk/inc/loadTasky.php','');
    }
    chcetasksk(data){
      return this.http.post('https://crm.becrea.sk/inc/chcetasksk.php',data);
    }
    loadSlaOne(data){
      return this.http.post('https://crm.becrea.sk/inc/loadSlaOne.php',data);
    }
    loadOneTaskData(data){
      return this.http.post('https://crm.becrea.sk/inc/loadOneTaskData.php',data);
    }
    loadOneTaskComment(data){
      return this.http.post('https://crm.becrea.sk/inc/loadOneTaskComment.php',data);
    }
    AdminAddComm(data){
        return this.http.post('https://sla.becrea.sk/inc/AdminAddComm.php',data);
    }
    cakamnatask(data){
        return this.http.post('https://crm.becrea.sk/inc/CakamNaComment.php',data);
    }
    getNewTimeSla(data){
        return this.http.post('https://crm.becrea.sk/inc/getNewTimeSla.php',data);
    }
    navhUza(data){
        return this.http.post('https://crm.becrea.sk/inc/navhUza.php',data);
    }

}
