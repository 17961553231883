import { Component, OnInit } from '@angular/core';
import {AuthService} from "../auth.service";
import {NgForm} from "@angular/forms";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material";

@Component({
  selector: 'app-root',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  projektov;zarobene;zarobeneteraz:any;
  firiem:any;
  ulohy:any;
  tasky:any;
  celkocas:any;
  casmesiac:any;
  poznamka:any;
  taskov:any;
  opentask:any;
  kalendar:any;

  constructor(
      private  Auth: AuthService,
      public snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    var iduser = sessionStorage.getItem('id');
    this.Auth.loadDas(iduser).subscribe(data => {
      console.log(data);
      this.projektov = data['projektov'];
      this.firiem = data['firiem'];
      this.ulohy = data['ulohy'];
      this.tasky = data['tasky'];
      this.poznamka = data['poznamka'];
      this.kalendar = data['kalendar'];
      this.zarobene = data['zarobene'];
      this.zarobeneteraz = data['zarobeneteraz'];
      this.taskov = data['taskovcelkovo'];
      this.opentask = data['otvorenzch'];
      this.casmesiac = data['casmesiac'];
      this.celkocas = data['celkocas'];
    });
  }

  sajvnipoynamku = function (form:NgForm) {
    var iduser = sessionStorage.getItem('id');
    let dataPoz = {
      'id': iduser,
      'poyznamka': form.value.poynamka
    }
    this.Auth.poznamkaAdd(dataPoz).subscribe(data => {
      if(data == 'ano'){
        console.log(data);
        let config = new MatSnackBarConfig();
        config.duration = 5000;
        config.panelClass = ['green_class'];
        this.snackBar.open('Ulozene!', "Ok", config);
      }else{
        console.log(data);

      }
    });
  }

}
