import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../auth.service";
import {ActivatedRoute} from "@angular/router";
import {NgForm} from "@angular/forms";
import Swal from 'sweetalert2'

@Component({
  selector: 'app-slatask',
  templateUrl: './slatask.component.html',
  styleUrls: ['./slatask.component.css']
})
export class SlataskComponent implements OnInit {

  jehoid;tasknazov;obsah;kedy;trvane;prilohy:any;
  otvorene:any;
  otvorenee:any;
  fileToUpload: File = null;
  uzavrete:any;  imgs:any;
  commenty:any;  uzivatelia:any;
  taskid:any;

  constructor(
      private  Auth: AuthService,
      private route: ActivatedRoute,
  ) { }

  ngOnInit() {



    var self = this;

    const idtask = this.route.snapshot.paramMap.get('idtask');
    this.taskid = idtask;
    console.log(idtask);
    let dataTime = {
      'taskid': idtask
    };
    this.Auth.loadOneTaskData(dataTime).subscribe(data => {
      console.log(data);
      this.tasknazov = data[0]['nazov'];
      this.obsah = data[0]['popis'];
      this.kedy = data[0]['kedy'];
      this.trvane = data[0]['trvane'];
      if(data[0]['cheuzav'] == 1){
        self.otvorenee = false;
      }else{
        self.otvorenee = true;
      }
      if(data[0]['ukoncne'] == 1){
        self.otvorene = false;
        self.otvorenee = false; 
      }else{
        self.otvorene = true;
      }

      this.imgs = data[1]['img'];

    })
    this.Auth.loadOneTaskComment(dataTime).subscribe(data => {
      console.log(data[0]);
      this.commenty = data[0];

    })

  }





  navrhUza(){
var self = this;
    Swal.fire({
      title: 'Ste si istý?',
      text: 'Naozaj checte navrhnúť uzavretie?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        var taskid = this.route.snapshot.paramMap.get('idtask');
        this.Auth.navhUza(taskid).subscribe(data => {
          console.log(data);
          if(data == 'ano'){
            self.otvorenee = false;
          }
        });

      }
    });

  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  comAdd(form:NgForm){

    const formData =  new  FormData();
    formData.append("komentar",  form.value.ine);
    formData.append("idtask",  this.route.snapshot.paramMap.get('idtask'));
    formData.append("idfirmy",  this.route.snapshot.paramMap.get('id'));
    formData.append('file', this.fileToUpload);

    this.Auth.AdminAddComm(formData).subscribe(data => {
      console.log(data);
      const id = this.route.snapshot.paramMap.get('id');
      this.jehoid = id;
      const idtask = this.route.snapshot.paramMap.get('idtask');
      this.taskid = idtask;
      let dataTime = {
        'jehoid': id,
        'taskid': idtask
      };
      this.Auth.loadOneTaskComment(dataTime).subscribe(data => {
        console.log(data[0]);
        this.commenty = data[0];
      })
    })
  }
}


