import {Component, OnInit, TemplateRef} from '@angular/core';
import {AuthService} from "../auth.service";
import {MatSnackBarConfig} from "@angular/material";
import {NgForm} from "@angular/forms";
import {MatDialog, MatTableDataSource,MatFormFieldModule} from "@angular/material";

@Component({
  selector: 'app-volne',
  templateUrl: './volne.component.html',
  styleUrls: ['./volne.component.css']
})
export class VolneComponent implements OnInit {

  constructor(
    private  Auth: AuthService,
    public dialog: MatDialog
  ) {}

  kalendar;taskysu;idIs;nayovIs:any;
  kalendaris:any;
  kalendarob:any;
  taskup;idje;idupravujem;color: any;
  drawero;niejet;aktoniejeur;datumod:any;
  ngOnInit() {
    var iduser = sessionStorage.getItem('id');
    this.Auth.loadPrac(iduser).subscribe(data => {
      console.log(data);
      this.kalendar = data['kalendar'];
      this.kalendaris = data['kalendaris'];
      this.kalendarob = data['kalendarob'];
    });

    this.Auth.loadTasky().subscribe(data => {
      console.log(data);
      this.taskysu = data[0];
    });
  }

  chcemtask(id,nayov,templateRef: TemplateRef<any>){
    this.dialog.open(templateRef);
    this.idIs = id;
    this.nayovIs = nayov;
  };

      chcetentask(form:NgForm){
    var iduser = sessionStorage.getItem('id');
    let dataFa = {
      'iduser': iduser,
      'idIs': form.value.idIs
    };
    this.Auth.chcetasksk(dataFa).subscribe(data => {
      if(data == 'ano'){
        console.log(data);
        window.location.href = '/volne';
      }else{
        console.log(data);

      }
    });
  }

  pridajPra(idcl){
    this.Auth.taskRob(idcl).subscribe(data => {
      if(data == 'ano'){
        console.log(data);
        window.location.href = '/volne';
      }else{
        console.log(data);
      }
    });
  }
  odoberPra(idcl){
    this.Auth.odoberPra(idcl).subscribe(data => {
      if(data == 'ano'){
        console.log(data);
        window.location.href = '/volne';
      }else{
        console.log(data);
      }
    });
  }
  odoberUrob(idcl){
    this.Auth.odoberUrob(idcl).subscribe(data => {
      if(data == 'ano'){
        console.log(data);
        window.location.href = '/volne';
      }else{
        console.log(data);
      }
    });
  }

  poslitask(form:NgForm){
    var iduser = sessionStorage.getItem('id');
    let dataFa = {
      'iduser': iduser,
      'datumod': form.value.datumod,
      'casod':form.value.casod,
      'datumdo': form.value.datumdo,
      'casdo':form.value.casdo,
      'task':form.value.task
    };
    this.Auth.pridajtask(dataFa).subscribe(data => {
      if(data == 'ano'){
        console.log(data);
        window.location.href = '/volne';
      }else{
        console.log(data);

      }
    });
  }


}
