import { Component, OnInit } from '@angular/core';
import {NgForm} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material";
import {AuthService} from "../auth.service";
import {Router} from "@angular/router";


@Component({
    selector: 'app-root',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    title:string;
    constructor(
        public http: HttpClient,
        public snackBar: MatSnackBar,
        private  Auth: AuthService,
        private router: Router
    ) {

    }
    ngOnInit() {
        this.title = 'login';
    }

    login(form:NgForm){
        let data = {
            'username': form.value.username,
            'password': form.value.password
        };
        let headers = {
           // 'Content-Type': 'application/json'
        };
        console.log(data);
        this.Auth.getUserDetails(data.username,data.password).subscribe(data => {

            if(data != 'nie'){
            let key = 'user';

             let value = data['user'];
                sessionStorage.setItem(key, value);
                let keys = 'id';
                let values = data['id'];
                sessionStorage.setItem(keys, values);

                window.location.href = '/dashboard';

            }else{
                console.log(data);
                let config = new MatSnackBarConfig();
                config.duration = 5000;
                config.panelClass = ['red_class'];
                this.snackBar.open('Meno, alebo heslo je neplatné!', "Ok", config);

            }
        });


    };


}
