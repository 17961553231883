import {Component, OnInit} from '@angular/core';
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    isObsah;isAdmin:boolean;

    constructor(private Auth:AuthService,
                private router:Router){  }

    ngOnInit() {
        this.isAdmin = false;
        this.isObsah = false;
       if(sessionStorage.getItem('user')){

           if(window.location.pathname == '/'){
               window.location.href = 'dashboard';
           }else{
               this.isAdmin = true;
               this.isObsah = true;
           }

        }else{
           if(window.location.pathname != '/'){
               window.location.href = '/';
           }else{
               this.isAdmin = false;
               this.isObsah = true;
           }


        }
    }

}
