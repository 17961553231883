import {Component, OnInit, TemplateRef} from '@angular/core';
import {AuthService} from "../auth.service";
import {NgForm} from "@angular/forms";
import {MatDialog, MatTableDataSource} from "@angular/material";
import {interval, Subscription} from "rxjs/index";
import {formatDate} from "@angular/common";

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {
    zonaklienti:any;
    dataSource:any;
    nc;casje;idje:any;
    today;play:any;
    source: Subscription;
    sekundak:number;
    dateFormat ='';
  constructor(private  Auth: AuthService,  public dialog: MatDialog) { }

    displayedColumns: string[] = ['firma', 'praca', 'kedy','cas', 'play' ];

  ngOnInit() {
      var iduser = sessionStorage.getItem('id');
      this.Auth.loadKlTime(iduser).subscribe(data => {
          this.zonaklienti = data[0];
        //  console.log(data);
      });

      this.loadnitime();

  }

  loadnitime(){
      var iduser = sessionStorage.getItem('id');
      this.Auth.loadTimes(iduser).subscribe(data => {
          console.log(data);
          this.dataSource = new MatTableDataSource<PeriodicElement>(data[0]);
      })
  }

    fakturaposlat(form:NgForm){
        var iduser = sessionStorage.getItem('id');
        let dataFa = {
            'iduser': iduser,
            'klient': form.value.klient,
            'varial': form.value.varial,
            'segment': form.value.segment
        };
        console.log(dataFa);
        this.Auth.getToTime(dataFa).subscribe(data => {
            if(data == 'ano'){
                console.log(data);
                this.loadnitime()
            }else{
                console.log(data);

            }
        });
    }

    spustcas(is){
        var casje = document.getElementById('casje'+is).innerHTML;
        var timeArr = casje.split(':');
       var date = new Date().setHours(Number(timeArr[0]), Number(timeArr[1]), Number(timeArr[2]))
        this.source = interval(1000).subscribe(
            val =>(
                 date = date+1000,
                  document.getElementById('casje'+is).innerHTML=formatDate(date,"HH:mm:ss","en-EN")
            )
        );

    }
    zastavcas(is){
        var casje = document.getElementById('casje'+is).innerHTML;
        this.source.unsubscribe();
        console.log(casje);
        let dataNc = {
            'id': is,
            'novzcas':  casje
        };
        this.Auth.getNewTime(dataNc).subscribe(data => {
            if(data == 'ano'){
                console.log(data);
                window.location.href = '/timer';
            }else{
                console.log(data);

            }
        });
    }

    zmenacasu(id,cas,templateRef: TemplateRef<any>){
        console.log(id+' '+cas);
        this.dialog.open(templateRef);
        this.casje = cas;
        this.idje = id;
    }

    poslinovzcas(){
        let dataNc = {
            'id': this.idje,
            'novzcas':  this.casje
        };
        this.Auth.getNewTime(dataNc).subscribe(data => {
            if(data == 'ano'){
                console.log(data);
                window.location.href = '/timer';
            }else{
                console.log(data);

            }
        });
    }

}

export interface PeriodicElement {
    firma: string;
    praca: string;
    kedy: string;
    cas: string;
    play: number;
}
