import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MaterialModule} from "./material.module";
import {HttpClientModule} from "@angular/common/http";
import {FormsModule} from "@angular/forms";
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { KalendarComponent } from './kalendar/kalendar.component';
import { TimerComponent } from './timer/timer.component';
import { ProjektyComponent } from './projekty/projekty.component';
import { FirmyComponent } from './firmy/firmy.component';

import {FullCalendarModule} from "ng-fullcalendar";
import { PriebehComponent } from './priebeh/priebeh.component';
import { VolneComponent } from './volne/volne.component';
import { SlaComponent } from './sla/sla.component';
import { SlataskComponent } from './slatask/slatask.component';





const appRoutes: Routes = [
     {
        path:'dashboard',
        component:DashboardComponent,
    },
    {
        path:'kalendar',
        component:KalendarComponent,
    },{
        path:'volne',
        component:VolneComponent,
    }, {
        path:'firmy',
        component:FirmyComponent,
    }, {
        path:'projekty',
        component:ProjektyComponent,
    }, {
        path:'priebeh',
        component:PriebehComponent,
    }, {
        path:'timer',
        component:TimerComponent,
    }, {
        path:'sla',
        component:SlaComponent,
    },{
        path:'sla/:id/:idtask',
        component:SlataskComponent,
    },{
         path:'',
        component:LoginComponent
    }];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    LoginComponent,
    KalendarComponent,
    TimerComponent,
    ProjektyComponent,
    FirmyComponent,
    PriebehComponent,
      VolneComponent,
      SlaComponent,
      SlataskComponent,

  ],
  imports: [
      BrowserModule,
      BrowserAnimationsModule,
      MaterialModule,
      HttpClientModule,
      FormsModule,
      RouterModule.forRoot(
          appRoutes,
          ),
      FullCalendarModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
