import { Component, OnInit, TemplateRef} from '@angular/core';
import {AuthService} from "../auth.service";
import {MatDialog, MatTableDataSource} from "@angular/material";


@Component({
  selector: 'app-sla',
  templateUrl: './sla.component.html',
  styleUrls: ['./sla.component.css']
})
export class SlaComponent implements OnInit {
  casje;idje;idfirmao:any;
  dataSource:any;
  displayedColumns: string[] = ['id','idfirma','firma','nazov', 'vytvorene', 'ukoncene', 'komentarov', 'cakam','dotime','dotimeall'];

  constructor(
      private  Auth: AuthService,  public dialog: MatDialog
  ) { }

  ngOnInit() {
    var iduser = sessionStorage.getItem('id');
    this.Auth.loadSlaOne(iduser).subscribe(data => {
      console.log(data);
      this.dataSource = new MatTableDataSource<PeriodicElement>(data[0]);
    });
  }
  zmenacasu(id,idfirma,cas,templateRef: TemplateRef<any>){
    console.log(id+' '+cas);
    this.dialog.open(templateRef);
    this.casje = cas;
    this.idje = id;
    this.idfirmao = idfirma;
  }
  cakamto(caka:any, taskje:any){
    let data = {
      'caka':caka,
      'taskje': taskje,
    };
    this.Auth.cakamnatask(data).subscribe(data => {
      if(data == 'ano'){
        var iduser = sessionStorage.getItem('id');
        this.Auth.loadSlaOne(iduser).subscribe(data => {
          console.log(data);
          this.dataSource = new MatTableDataSource<PeriodicElement>(data[0]);
        });
      }else{
        console.log(data);
      }
    });
  }
  poslinovzcas(){
    var iduser = sessionStorage.getItem('id');
    let dataNc = {
      'id': this.idje,
      'idfirma':  this.idfirmao,
      'novzcas':  this.casje,
      'iduser':  iduser,
    };
    console.log(dataNc);
    this.Auth.getNewTimeSla(dataNc).subscribe(data => {
      if(data == 'ano'){
        console.log(data);
        window.location.href = '/sla';
      }else{
        console.log(data);

      }
    });
  }
}

export interface PeriodicElement {
  id: string;
  firma: string;
  idfirma:number;
  nazov: string;
  vytvorene: string;
  cakam: string;
  ukoncene: number;
  komentarov: number;
  dotime: string;
  dotimeall: string;
}
